import { Carousel, Slide } from '@zento-lib/components/Carousel';
import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import type { IProductState } from 'theme/@types/vsf/stores/product';
import { prepareRelatedQuery } from 'theme/stores/catalog/queries/related';
import { AppContextStore, KEY as AppKey } from 'theme/@types/zento/stores/applicationContext';

import { ProductBoxGrid } from '../../../organism/ProductBoxGrid/ProductBoxGrid';

import type { ICrosssellProducts } from './Crosssell.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(AppKey);

/**
 * Crosssell Products
 *
 * Renders a list of crosssell products as carousel
 */
@Component({})
export class CrosssellProducts extends BaseComponent<ICrosssellProducts, unknown> {
  private static T = {
    crosssellProductsTitle: 'crosssell_product_widget_title',
  };

  @Prop({ type: Array, required: true })
  cartProducts: IProductState[];

  /**
   * Configure the number of visible slides with a particular browser width.
   */
  @Prop({ type: Array, required: true })
  perPageCustom: number[][];

  @appContextStore.Getter('isDesktop')
  protected isDesktop: boolean;

  data() {
    return {
      crossellProducts: [],
      componentLoaded: false,
    };
  }

  async mounted() {
    await this.fetchCrosssellCollection();

    this.$nextTick(() => {
      this.$data.componentLoaded = true;
    });
  }

  render() {
    return this.$data.crossellProducts.length > 0 ? (
      <div class={[style.collectionWrapper, style.container]}>
        <h2 class={style.collectionTitle} key='title'>
          {this.getTranslation({ id: CrosssellProducts.T.crosssellProductsTitle })}
        </h2>
        {this.$data.componentLoaded ? (
          <Carousel
            perPage={2}
            perPageCustom={this.perPageCustom}
            mouseDrag={true}
            navigationEnabled={this.isDesktop ?? false}
            paginationEnabled={true}
            centerMode={true}
            scrollPerPage={this.isDesktop}
            easing='ease-in-out'
            speed={this.isDesktop ? 500 : 200}
            class={style.collectionItems}
            key='crossell-products-carousel'>
            {this.$data.crossellProducts.map((product: IProductState, index: number) => {
              return (
                <Slide key={product.id + '-' + product.sku + '-crossell'}>
                  <ProductBoxGrid product={product} position={index} />
                </Slide>
              );
            })}
          </Carousel>
        ) : null}
      </div>
    ) : null;
  }

  /**
   * Determin skus for fetch from existing cart products
   */
  private get crosssellSkus() {
    const skus = this.cartProducts.map((item) =>
      (item.product_links || []).filter((pl) => pl.link_type === 'crosssell').map((pl) => pl.linked_product_sku),
    );

    return this.flatten(skus).filter(this.onlyUnique);
  }

  /**
   * Fetch list of crosssell product information
   */
  private async fetchCrosssellCollection() {
    const relatedProductsQuery = prepareRelatedQuery('sku', this.crosssellSkus);

    await this.$store
      .dispatch('product/list', {
        query: relatedProductsQuery,
        prefetchGroupProducts: false,
        updateState: false,
      })
      .then((response) => {
        if (response && response.items.length) {
          this.$data.crossellProducts = response.items;
        }
      });
  }

  /**
   * Transform multi-dimensional arrays to a single flat (one-dimensional) one
   */
  private flatten(arr: any[]) {
    return arr.reduce((flat, toFlatten) => {
      return flat.concat(Array.isArray(toFlatten) ? this.flatten(toFlatten) : toFlatten);
    }, [] as string[]);
  }

  /**
   * Filter out duplicated values
   */
  private onlyUnique(value: string, index: number, self: string[]) {
    return self.indexOf(value) === index;
  }
}
